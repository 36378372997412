import React from "react";
import PropTypes from "prop-types";
import Img from "gatsby-image";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { useStaticQuery, graphql } from "gatsby";

import { FaBirthdayCake, FaUsers, FaStar, FaRocket, FaAward, FaUserPlus, FaCog } from "react-icons/fa";

const historyData = [
    {
        year: 2005,
        icon: <FaBirthdayCake />,
        title: "The beginning",
        content:
            "Anton Nekrutenko and James Taylor recognized the need for simple but modular web interfaces to guide scientific computation.",
    },
    {
        year: 2008,
        icon: <FaAward />,
        title: "Off we grow",
        content: "First 2,000 users ran their jobs on a public server.",
    },
    {
        year: 2010,
        icon: <FaStar />,
        title: "Larger analyses",
        content: "Workflows are integrated into Galaxy enabling multiple jobs to be linked together.",
    },
    {
        year: 2012,
        icon: <FaUsers />,
        title: "Building a community",
        content: "Galaxy is growing organically with active contributors emerging around the world.",
    },
    {
        year: 2018,
        icon: <FaRocket />,
        title: "Go world-wide",
        content: "UseGalaxy.* initiative is adopted with managed Galaxy servers available around the world.",
    },
    {
        year: 2019,
        icon: <FaBirthdayCake />,
        title: "Add commercial services",
        content: "GalaxyWorks company is formed offering Galaxy-related services.",
    },
    {
        year: 2021,
        icon: <FaUserPlus />,
        title: "Galaxy Pro Researcher",
        content: "A first-ever self sign-up Galaxy service becomes available.",
    },
    {
        year: 2022,
        icon: <FaCog />,
        title: "Galaxy Pro Deployment",
        content: "Introducing fully-automated deployment in minutes.",
    },
];

const HistorySection = () => (
    <div className="columns sliding-columns">
        {historyData.map((node, key) => (
            <div className="column is-one-half" key={key}>
                <div className="card sliding-card p-6 mb-4">
                    <div className="is-size-1 has-text-primary">{node.icon}</div>
                    <p className="title has-text-weight-bold has-text-primary is-size-5 mb-5">{node.year}</p>
                    <div className="card-body">
                        <p className="has-text-weight-bold has-text-primary is-size-3 mb-3">{node.title}</p>
                        <div className="content ">
                            <p className="subtitle has-text-dark is-6" style={{ lineHeight: "1.7rem" }}>
                                {node.content}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        ))}
    </div>
);

const AboutMember = (props) => (
    <div className="card">
        <div className="card-image">
            <figure className="image">
                <Img durationFadeIn={500} fluid={props.image.childImageSharp.fluid} />
            </figure>
        </div>
        <div className="card-content-overlay">
            <div className="content p-sm columns">
                <div className="column is-two-thirds has-text-dark">
                    <div className="title is-4">{props.first}</div>
                    <div className="subtitle is-5">{props.name}</div>
                </div>
                <div className="column title has-text-dark has-text-right">{props.title}</div>
            </div>
        </div>
    </div>
);

AboutMember.propTypes = {
    name: PropTypes.string,
    first: PropTypes.string,
    title: PropTypes.string,
    image: PropTypes.object,
};

export const headshotImage = graphql`
    fragment headshotImage on File {
        childImageSharp {
            fluid(maxWidth: 500, maxHeight: 500, grayscale: true) {
                ...GatsbyImageSharpFluid_tracedSVG
            }
        }
    }
`;

const query = graphql`
    query {
        afgan: file(relativePath: { eq: "team/afgan.jpg" }) {
            ...headshotImage
        }
        blankenberg: file(relativePath: { eq: "team/blankenberg.jpg" }) {
            ...headshotImage
        }
        chilton: file(relativePath: { eq: "team/chilton-square.jpg" }) {
            ...headshotImage
        }
        coraor: file(relativePath: { eq: "team/coraor-square.jpg" }) {
            ...headshotImage
        }
        dannon: file(relativePath: { eq: "team/dannon-square.jpg" }) {
            ...headshotImage
        }
        goecks: file(relativePath: { eq: "team/goecks-square.jpg" }) {
            ...headshotImage
        }
        goonasekera: file(relativePath: { eq: "team/goonasekera-square.png" }) {
            ...headshotImage
        }
        guerler: file(relativePath: { eq: "team/guerler-square.png" }) {
            ...headshotImage
        }
        nekrutenko: file(relativePath: { eq: "team/nekrutenko-square.jpg" }) {
            ...headshotImage
        }
        taylor: file(relativePath: { eq: "team/taylor-square.jpg" }) {
            ...headshotImage
        }
        sjkp: file(relativePath: { eq: "team/sjkp.jpg" }) {
            ...headshotImage
        }
        roach: file(relativePath: { eq: "team/roach-square.jpg" }) {
            ...headshotImage
        }
        guertin: file(relativePath: { eq: "team/randy-guertin.jpg" }) {
            ...headshotImage
        }
        sauria: file(relativePath: { eq: "team/sauria.jpg" }) {
            ...headshotImage
        }
    }
`;

const AboutPage = () => {
    const data = useStaticQuery(query);
    return (
        <Layout>
            <SEO title="About Us" />
            <section className="hero is-primary is-small">
                <div className="hero-body">
                    <div className="container has-text-centered">
                        <h1 className="title has-text-warning">The Galaxy Story</h1>
                        <HistorySection />
                    </div>
                </div>
            </section>
            <section>
                <div className="hero-body">
                    <div className="container">
                        <div className="columns">
                            <div className="column">
                                <AboutMember name="Baker" first="Dannon" image={data.dannon} />
                            </div>
                            <div className="column">
                                <AboutMember name="Blankenberg" first="Daniel" image={data.blankenberg} />
                            </div>
                            <div className="column">
                                <AboutMember name="Chilton" first="John" image={data.chilton} />
                            </div>
                            <div className="column">
                                <AboutMember name="Coraor" first="Nate" image={data.coraor} />
                            </div>
                        </div>
                        <div className="columns">
                            <div className="column">
                                <AboutMember name="Goecks" first="Jeremy" image={data.goecks} />
                            </div>
                            <div className="column">
                                <AboutMember name="Guerler" first="Aysam" image={data.guerler} />
                            </div>
                            <div className="column">
                                <AboutMember name="Nekrutenko" first="Anton" image={data.nekrutenko} />
                            </div>
                            <div className="column">
                                <AboutMember name="Sauria" first="Mike" image={data.sauria} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    );
};

export default AboutPage;
